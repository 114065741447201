// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-trainings-cqrs-event-sourcing-axon-js": () => import("./../../../src/pages/trainings/cqrs-event-sourcing-axon.js" /* webpackChunkName: "component---src-pages-trainings-cqrs-event-sourcing-axon-js" */),
  "component---src-pages-trainings-domain-driven-design-applied-js": () => import("./../../../src/pages/trainings/domain-driven-design-applied.js" /* webpackChunkName: "component---src-pages-trainings-domain-driven-design-applied-js" */)
}

